import { createRouter, createWebHistory } from 'vue-router';
import MainPage from '@/components/MainPage.vue';
import ChangelogTable from '@/components/ChangelogTable.vue';

const routes = [
  {
    path: '/',
    name: 'MainPage',
    component: MainPage
  },
  {
    path: '/download',
    name: 'ChangelogTable',
    component: ChangelogTable,
    //props: route => ({ info: route.query.info })
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

// Set the document title dynamically based on the route's meta title
router.beforeEach((to, from, next) => {
  // document.title = 'LogBase 报表 - ' + to.meta.title;
  document.title = 'LogBase 更新日志';
  next();
});

export default router;

