<template>
  <div id="app">
    <ChangelogTable />
  </div>
</template>

<script>
import ChangelogTable from './ChangelogTable.vue';

export default {
  components: {
    ChangelogTable,
  },
};
</script>
