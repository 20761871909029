import axios from 'axios';

const api = axios.create({
baseURL: '/',
  timeout: 10000, // 请求超时设定
});

export default {
  getPatch() {
    return api.post('/api/get_patch');
  },
  getNote(data) {
    return api.post('/api/get_note', data);
  }
};
