<template>
    <div v-if="isOpen" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" @click="closeModal">
      <div class="bg-white p-6 rounded-lg shadow-lg w-1/3" @click.stop>
        <h2 class="text-2xl font-bold mb-4">{{ title }}</h2>
        <div class="mb-4">
          <slot></slot> <!-- 这里是插槽，用于在使用组件时传入内容 -->
        </div>
        <button @click="closeModal" class="px-4 py-2 bg-red-500 text-white rounded">关闭</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CustomModal', // 修改为多词名称
    props: {
      isOpen: {
        type: Boolean,
        required: true,
      },
      title: {
        type: String,
        default: '模态弹窗',
      }
    },
    methods: {
      closeModal() {
        this.$emit('close'); // 触发关闭事件，让父组件控制弹窗的显示状态
      }
    }
  };
  </script>
  
  <style scoped>
  /* 可选的额外样式 */
  </style>
  