<template>
  <div id="app" class="text-center">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
/* 你可以在这里添加自定义样式 */
</style>
